/*--------head------------*/
.head {
  padding: 0;
  color: #fff;
}
.logo img {
  width: 100%;
  height: 100%;
  max-width: 120px;
  margin-left: -20px;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(0, 131, 203, 0.1);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin: 0;
}
header ul {
  padding: 0;
}
.social{
  padding-top: 30px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
  display: block;
  padding: 30px 30px;
}
header ul li a:hover {
  color: #0083cb;
  border-bottom: 2px solid #0083cb;
}
.start {
  background-color: #0083cb;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #0083cb;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #0083cb;
  }
}
